import React, { forwardRef } from "react";
import "./style.css";
import { ChatMessage } from "../../../helpers/OpenAIHelper";
type MessageProps = {
  message: ChatMessage;
};

const MessageBubble = forwardRef<HTMLDivElement, MessageProps>(
  ({ message }, ref) => {
    if (message.content === "typing") {
      return (
        <div className="bot-message typing">
          <span></span>
          <span></span>
          <span></span>
        </div>
      );
    } else {
      return (
        <div
          ref={ref}
          className={
            message.role === "assistant" ? "bot-message" : "user-message"
          }
        >
          {message.content ? (
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
          ) : (
            // Render an empty div or some placeholder content if message.content is undefined
            <div></div>
          )}
        </div>
      );
    }
  }
);

export default MessageBubble;
