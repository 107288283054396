import { useState, useEffect, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";

import "./App.css";
import Header from "./components/header";
import MessageWindow from "./components/message-window";
import MessageInput from "./components/message-input";
import { ChatMessage } from "./helpers/OpenAIHelper";

const requiredKeys = ["REACT_APP_CHAT_BACK_URL"];
let missingKeys = requiredKeys.filter((key) => !process.env[key]);

if (missingKeys.length) {
  console.error(
    `Error: The following environment variable(s) were not provided: ${missingKeys.join(
      ", "
    )}`
  );
}

const SERVER_ADDRESS = process.env.REACT_APP_CHAT_BACK_URL;

const ChatConfigSchema = z.object({
  title: z.string(),
  openingMessagePlaceholder: z.string(),
});

type ChatConfig = z.infer<typeof ChatConfigSchema>;

function App() {
  const [searchParams] = useSearchParams();
  const authToken = searchParams.get("auth") || "";
  const userId = searchParams.get("user") || "";
  const role = searchParams.get("role") || undefined;

  const sessionId = uuidv4();
  const [config, setConfig] = useState<ChatConfig | null>(null);

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const chatContainerRef = useRef<HTMLDivElement | null>(null);

  const fetchAndSetConfig = useCallback(() => {
    fetch(`${SERVER_ADDRESS}/config`, {
      headers: {
        "x-api-key": authToken,
      },
    })
      .then((response) => {
        const contentType = response.headers.get("content-type");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        } else if (!contentType || !contentType.includes("application/json")) {
          console.error(
            `expected application/json content type from /config, but got ${contentType}`
          );
          return response.text();
        }
        return response.json();
      })
      .then((data) => {
        console.log("Received config data:", data);
        const result = ChatConfigSchema.safeParse(data.data);
        if (result.success) {
          setConfig(result.data);
        } else {
          console.error("Invalid config received from server", result.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
        console.error("Stack trace:", error.stack); // print the stack trace
      });
  }, [authToken]);

  useEffect(() => {
    fetchAndSetConfig();
  }, [fetchAndSetConfig]);

  const promptAssistant = useCallback(() => {
    fetch(`${SERVER_ADDRESS}/chat/completion`, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": authToken,
      },
      method: "POST",
      body: JSON.stringify({
        user_id: userId,
        session_id: sessionId,
        user_roles: role ? [role] : [],
        chat_history: messages.slice(0, -1),

        // about_me: "My name is Dean. I work 80% time for a mid-sized internet company, as a software engineer. I am also CTO of a small AI-based startup. I have decades of leadership experience, although mostly in early-stage companies.",
        // how_to_respond: "Just be yourself! You are pretty awesome."
      }),
    })
      .then(async (response) => {
        setIsTyping(false);
        if (response.ok) {
          return response.body;
        } else {
          const errorMessage = `Request failed with status code ${response.status}`;
          const responseBody = await response.text();
          throw new Error(`${errorMessage}. Response body: ${responseBody}`);
        }
      })
      .then(async (body: ReadableStream<Uint8Array> | null) => {
        if (body !== null) {
          const reader = body.getReader();
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            } else {
              const token = new TextDecoder().decode(value);
              setMessages((currentMessages) => {
                const lastMessageIndex = currentMessages.length - 1;
                const lastMessageContent =
                  currentMessages[lastMessageIndex].content || "";
                const updatedLastMessage = {
                  ...currentMessages[lastMessageIndex],
                  content: lastMessageContent + token,
                };
                return [...currentMessages.slice(0, -1), updatedLastMessage];
              });
            }
          }
        }
      })
      .catch((error) => {
        setMessages((currentMessages) => {
          const lastMessageIndex = currentMessages.length - 1;
          const updatedLastMessage = {
            ...currentMessages[lastMessageIndex],
            content: `Error: ${error.message}`,
          };
          return [...currentMessages.slice(0, -1), updatedLastMessage];
        });
      });
  }, [messages, authToken, sessionId, role, userId]);

  const addMessageAndPromptAssistant = useCallback((message: ChatMessage) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      message,
      { role: "assistant", content: "" },
    ]);
    setIsTyping(true);
  }, []);

  useEffect(() => {
    if (isTyping) {
      promptAssistant();
    }
  }, [isTyping, promptAssistant]);

  const startNewChat = useCallback(() => {
    setMessages([]);
    fetchAndSetConfig();
  }, [fetchAndSetConfig]);

  if (!config) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="container">
        <Header onNewChat={startNewChat} title={config.title} />
        <MessageWindow
          messages={messages}
          isTyping={isTyping}
          ref={chatContainerRef}
        />
        <MessageInput
          onMessageSent={addMessageAndPromptAssistant}
          placeholder={
            messages.length === 0
              ? config.openingMessagePlaceholder
              : "Your message"
          }
        />
      </div>
    );
  }
}

export default App;
